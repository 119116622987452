import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Avatar, Button, FlexRow, colors, spacing } from '@orus.eu/pharaoh'
import type { Permission } from '@orus.eu/right-access-management'
import { isSubsetOf } from '@orus.eu/sets'
import { useNavigate } from '@tanstack/react-router'
import { memo, useCallback, type ReactNode } from 'react'
import { useSession } from '../../lib/session'
import { OrusIconSvg } from '../atoms/orus-icon-svg'
import { BackofficeMenu } from '../organisms/backoffice-menu'
import { InviteToLoginPage } from './invite-to-login/InviteToLoginPage'
import { MenuPage } from './menu-page'

const menuOrusIconWidthInPx = 28.92

const BackofficePage = memo<{
  children: ReactNode
  requiredPermissions: Permission[]
}>(function BackofficePage({ children, requiredPermissions }) {
  const session = useSession()

  if (!session) return <InviteToLoginPage />
  if (session.permissions?.type !== 'platform') return <InviteToLoginPage />
  if (
    !isSubsetOf(
      new Set(requiredPermissions),
      new Set(session.permissions.rolesPermissions.flatMap((role) => role.permissions)),
    )
  )
    return <InviteToLoginPage />

  return (
    <MenuPage
      menuTitle={<BackofficeMenuTitle />}
      menuBody={
        <div
          css={css`
            padding: 0 ${spacing[30]} ${spacing[30]} ${spacing[30]};
            width: 240px;
          `}
        >
          <BackofficeMenu />
        </div>
      }
      pageContent={children}
      border={`1px solid ${colors.gray[100]}`}
    />
  )
})
export default BackofficePage

export const BackofficeMenuTitle = memo(function BackofficeMenuTitle() {
  const navigate = useNavigate()
  const navigateToSearch = useCallback(() => void navigate({ to: '/bak/search' }), [navigate])
  return (
    <TitleRow>
      <OrusIconSvg widthPx={menuOrusIconWidthInPx} />

      <Button
        variant="secondary"
        size="small"
        avatar={<Avatar icon="magnifying-glass-solid" />}
        onClick={navigateToSearch}
        css={css`
          border-radius: 100%;
        `}
      />
    </TitleRow>
  )
})

const TitleRow = styled(FlexRow)`
  justify-content: space-between;
  padding: ${spacing[50]} ${spacing[60]};
`
