import styled from '@emotion/styled'
import { FlexSpacedColumn, Icon, Text, colors, spacing } from '@orus.eu/pharaoh'
import { usePermissions } from '../../lib/use-permissions'
import { DisconnectButtonLink } from '../molecules/disconnect-button-link'
import { NavMenuItem } from '../molecules/nav-menu-item'
import { navMenuItemSx } from '../molecules/nav-menu-item-style'

export function BackofficeMenu(): JSX.Element {
  const permissions = usePermissions()

  return (
    <nav
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <FlexSpacedColumn gap={spacing[20]} margin={`${spacing[70]} 0`} padding="0 0">
        <NavMenuItem to="/bak/home" icon="house-solid" label="Accueil" variant="backoffice" />

        {permissions.permissions.includes('subscription.create') ? (
          <NavMenuItem to="/bak/pending-subscriptions" icon="clock-three-solid" label="Devis" variant="backoffice" />
        ) : (
          <></>
        )}
        <NavMenuItem to="/bak/users" icon="folder-user-solid" label="Clients" variant="backoffice" />
      </FlexSpacedColumn>
      <FlexSpacedColumn gap={spacing[20]} margin={`0 0`} padding="0 0">
        {import.meta.env.PROD && permissions.permissions.includes('pharaoh.read') ? (
          <NavMenuItem
            onClick={() => (window.location.href = '/pharaoh/')}
            target="_blank"
            icon="paint-roller-solid"
            label="Pharaoh"
            variant="backoffice"
          />
        ) : (
          <></>
        )}

        {permissions.permissions.includes('subscription.changeOwner') ? (
          <NavMenuItem to="/bak/migration" icon="arrows-left-right-regular" label="Migration" variant="backoffice" />
        ) : (
          <></>
        )}
        <NavMenuItem to="/bak/documentation" icon="umbrella-simple-solid" label="Documentation" variant="backoffice" />

        {permissions.permissions.includes('activities.read') ? (
          <NavMenuItem to="/bak/activities" icon="screwdriver-wrench-solid" label="Activities" variant="backoffice" />
        ) : (
          <></>
        )}
        {permissions.permissions.includes('organization.read') ? (
          <NavMenuItem to="/bak/organizations" icon="people-roof-solid" label="Organizations" variant="backoffice" />
        ) : (
          <></>
        )}
        {permissions.permissions.includes('organization.read') ? (
          <NavMenuItem to="/bak/memberships" icon="users-solid" label="Memberships" variant="backoffice" />
        ) : (
          <></>
        )}
        {permissions.permissions.includes('invoices.pay') ? (
          <NavMenuItem
            to="/bak/admin/invoicing-process-actions"
            icon="file-invoice-solid"
            label="Invoicing actions"
            variant="backoffice"
          />
        ) : (
          <></>
        )}
        {permissions.permissions.includes('job.runEphemeral') ? (
          <NavMenuItem to="/bak/ephemeral-jobs" icon="list-check-solid" label="Jobs" variant="backoffice" />
        ) : (
          <></>
        )}
        {permissions.permissions.includes('tech.configurations') ? (
          <NavMenuItem to="/bak/admin" icon="gear-solid" label="Admin" variant="backoffice" />
        ) : (
          <></>
        )}
        <BackofficeDisconnectButton />
      </FlexSpacedColumn>
    </nav>
  )
}

function BackofficeDisconnectButton() {
  return (
    <DisconnectButtonLink sx={navMenuItemSx}>
      <FlexCenter>
        <Icon icon="arrow-right-to-bracket-solid" color={colors.gray[700]} />
      </FlexCenter>
      <Text variant="body2Medium">Se déconnecter</Text>
    </DisconnectButtonLink>
  )
}

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${spacing[50]};
  height: ${spacing[50]};
`
